* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.page {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

.pageTitle {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    flex-shrink: 0;
}

.title {
    font-size: 75px;
    color: rgb(211 175 89);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    scroll-snap-align: start;
    flex-shrink: 0;
}

.image {
    width: auto;
    height: 100%;
}

.textBlock {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
}

p {
    color: aliceblue;
}

.name {
    color: rgb(211 175 89);
    font-size: 40px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.years {
    color: rgb(206, 206, 206);
    font-size: 19px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.description {
    width: 600px;
    font-size: 26px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: aliceblue;
    text-align: center;
    line-height: 30px;
}

/* Стилизация ползунка скролла */
.page::-webkit-scrollbar {
    width: 12px;
}

.page::-webkit-scrollbar-track {
    background: black;
    border-radius: 10px;
}

.page::-webkit-scrollbar-thumb {
    background: rgb(211 175 89);
    border-radius: 10px;
    border: 3px solid black;
}

.page::-webkit-scrollbar-thumb:hover {
    background: rgb(148, 121, 59);
}

.page {
    scrollbar-width: thin;
    scrollbar-color: rgb(211 175 89) black;
}

/* Анимации */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

.descriptionVisible {
    animation: fadeIn 0.5s ease-in-out forwards;
}

.descriptionHidden {
    animation: fadeOut 0.5s ease-in-out forwards;
}

/* Кнопка "Наверх" */
.scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgb(211 175 89);
    color: black;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.scrollToTopButton:hover {
    background-color: rgb(148, 121, 59);
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .page {
        scroll-snap-type: none; /* Отключаем прилипание при скролле */
    }

    .pageTitle {
        height: 100vh; /* Занимает всю высоту экрана */
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        font-size: 40px;
        padding: 0 20px;
    }

    .container {
        flex-direction: column; /* Всегда вертикальный макет */
        height: auto;
        padding: 40px 20px; /* Увеличиваем отступы */
    }

    /* Убедимся, что второй блок тоже отображается в правильном порядке */
    .container:nth-child(3) {
        flex-direction: column-reverse;
    }

    .image {
        width: 100%;
        height: auto;
        margin-bottom: 40px; /* Увеличиваем отступ снизу */
    }

    .textBlock {
        width: 100%;
        padding: 0 20px;
    }

    .name {
        font-size: 30px;
    }

    .years {
        font-size: 16px;
    }

    .description {
        width: 100%;
        font-size: 20px;
        line-height: 24px;
    }

    /* Увеличиваем отступы между блоками */
    .container + .container {
        margin-top: 40px;
    }
}